import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";

import { mainApi } from "../../providers/api";
import FormControl from "../../inputs/FormControl";
import { ShowConfirm, ShowWarning } from "../../providers/alert";
import { FaEdit, FaTimes, FaTrash } from "react-icons/fa";
import { TAG_OPTIONS } from "../../pages/Articles";

export const CATEGORIES = {
	metro: "UB METRO",
	brt: "UB BRT",
	cablecar: "UB CABLECAR",
	greengate: "UB GREENGATE",
};

export const TAGS = {
	article: "Үйл явдлын мэдээ",
	knowledge: "Танин мэдэхүй",
	// highlight: "Онцлох",
	interview: "Ярилцлага",
	publishment: "Нийтлэл",
};

const ArticleForm = () => {
	const navigate = useNavigate();
	const { article_id: id } = useParams();

	const [loading, setLoading] = useState(id);
	const [readonly, setReadonly] = useState(id);

	const [initialValues, setInitialValues] = useState({
		name: { mn: "", en: "" },
		content: { mn: "", en: "" },
		thumb: [],
		category: "",
		tags: [],
	});

	useEffect(() => {
		if (id && readonly) fetchItem(id);
	}, [id, readonly]);

	const fetchItem = async (id) => {
		try {
			setLoading(true);

			const { data } = await mainApi(`article/${id}`);

			setInitialValues(data);
		} catch (error) {
			ShowWarning(error);
		} finally {
			setLoading(false);
		}
	};

	const deleteItem = async () => {
		try {
			setLoading(true);

			await mainApi({
				method: "DELETE",
				url: `article/${id}`,
			});

			navigate(-1);
		} catch (error) {
			ShowWarning(error);
		} finally {
			setLoading(false);
		}
	};

	const saveItem = async (values) => {
		try {
			setLoading(true);

			await mainApi({
				method: id ? "PUT" : "POST",
				url: id ? `/article/${id}` : `/article`,
				data: values,
			});

			navigate(-1);
		} catch (error) {
			ShowWarning(error);
		} finally {
			setLoading(false);
		}
	};

	return (
		<Formik
			initialValues={initialValues}
			enableReinitialize={true}
			validationSchema={Yup.object({
				title: Yup.object({
					mn: Yup.string().required("Гарчиг Монгол хэлээр оруулна уу"),
					en: Yup.string().required("Гарчиг Англи хэлээр оруулна уу"),
				}),
				category: Yup.object().required("Ангилал сонгоно уу"),
				thumb: Yup.array().min(1, "Зураг оруулна уу"),
			})}
			onSubmit={(values, { setSubmitting }) => {
				saveItem(values);
				setSubmitting();
			}}
		>
			<div className="p-5 border shadow-md flex flex-col gap-5 bg-white">
				<h1 className="bordered flex justify-between items-center">
					{id ? "Мэдээ мэдээлэл" : "Мэдээлэл шинээр бүртгэх"}
					{id && (
						<div className="flex gap-2">
							<button
								type="button"
								className="red"
								onClick={async () => {
									const confirm = await ShowConfirm(
										"Энэ мэдээллийг устгахдаа итгэлтэй байна уу"
									);
									if (confirm.isConfirmed) deleteItem();
								}}
								disabled={loading}
							>
								<FaTrash></FaTrash>
							</button>
							<button
								disabled={loading}
								type="button"
								onClick={() => {
									setReadonly(!readonly);
									if (!readonly) setInitialValues();
								}}
							>
								{readonly ? (
									<>
										<FaEdit></FaEdit>
									</>
								) : (
									<>
										<FaTimes></FaTimes>
									</>
								)}
							</button>
						</div>
					)}
				</h1>
				<Form className="gap-5 flex flex-col">
					<Field
						component={FormControl}
						label="Зураг"
						name="thumb"
						fetch={loading}
						readonly={readonly}
						type="file"
						axio={mainApi}
						count={10}
						height={150}
					/>
					<div className="grid grid-cols-2 gap-5">
						<Field
							component={FormControl}
							label="Гарчиг /MN/"
							name="title.mn"
							fetch={loading}
							readonly={readonly}
							type="text"
						/>
						<Field
							component={FormControl}
							label="Гарчиг /EN/"
							name="title.en"
							fetch={loading}
							readonly={readonly}
							type="text"
						/>
					</div>
					<Field
						component={FormControl}
						label="Нийтлэх огноо"
						name="published"
						fetch={loading}
						readonly={readonly}
						type="date"
					/>
					<Field
						component={FormControl}
						label="Мэдээний ангилал"
						name="category"
						fetch={loading}
						readonly={readonly}
						type="select"
						options={["metro", "brt", "cablecar", "greengate"].map((v) => ({
							value: v,
							label: CATEGORIES[v],
						}))}
					/>
					<Field
						component={FormControl}
						label="Мэдээний TAG"
						name="tags"
						fetch={loading}
						readonly={readonly}
						isMulti={true}
						type="auto"
						options={TAG_OPTIONS.map((v) => ({
							value: v,
							label: TAGS[v],
						}))}
					/>
					<div className="grid grid-cols-2 gap-5">
						<Field
							component={FormControl}
							label="Агуулга /MN/"
							name="content.mn"
							fetch={loading}
							readonly={readonly}
							type="rich"
						/>
						<Field
							component={FormControl}
							label="Агуулга /EN/"
							name="content.en"
							fetch={loading}
							readonly={readonly}
							type="rich"
						/>
					</div>
					<Field
						component={FormControl}
						name={id ? "Хадгалах" : "Шинээр бүртгэх"}
						fetch={loading}
						readonly={readonly}
						type="submit"
						containerClass="self-end primary"
					/>
				</Form>
			</div>
		</Formik>
	);
};

export default ArticleForm;
